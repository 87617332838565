import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

// Define TypeScript types for the API response
interface PageData {
  page_code: string;
  page_title: string;
  page_description: string;
  page_meta_title: string;
  page_meta_description: string;
  page_keywords: string;
  page_breadcrumb_url: string;
  page_alias: string;
  Active: string;
}

interface PageDataState {
  page: PageData | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

// Define a thunk for fetching page data
export const fetchPageData = createAsyncThunk<PageData, string, { rejectValue: string }>(
  'pageData/fetchPageData',
  async (pageCode, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_BASEAPI}/api/v1/page/default`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'BBQ-Client-Id': process.env.BBQ_Client_Id || "",
          'BBQ-Client-Secret': process.env.BBQ_Client_Secret || "",
        },
      });
      const data: PageData[] = await response.json();
      const page = data.find((page) => page.page_code === pageCode);
      if (!page) throw new Error('Page not found');
      return page;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'An error occurred');
    }
  }
);

// Define the initial state for the pageData
const initialState: PageDataState = {
  page: null,
  status: 'idle',
  error: null,
};

// Create slice for page data
const pageDataSlice = createSlice({
  name: 'pageData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPageData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPageData.fulfilled, (state, action: PayloadAction<PageData>) => {
        state.status = 'succeeded';
        state.page = action.payload;
      })
      .addCase(fetchPageData.rejected, (state:any, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default pageDataSlice.reducer;
