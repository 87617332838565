"use client";
import Icon from "@/components/Icon";
import SliderSection from "@/components/sidebars/reservationSidebar/Slider";
import Button from "@/components/ui/Button";
import { reservationSidbarOpenPopup } from "@/store/slices/auth/signPopupSlice";
import { AppDispatch, AppState } from "@/store/store";
import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Services = () => {
    const selectedBranch = useSelector((state: AppState) => state.branches.saved);
    const dispatch = useDispatch<AppDispatch>();
    return (
        <section className="md:py-[25px] pb-6 slider-sec" id="service-sec">
            <div className="container">
                <h2 className="font-poppins-32-regular !font-medium mb-4 md:mb-9">Our Offerings</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-[30px]">
                    <Image loading='lazy' src="/images/service1.jpg" alt="error" width={585} height={390} className="block rounded-xl w-full h-full object-cover" />
                    <div className="bg-primary-1 rounded-xl flex p-4">
                        <div className="md:m-auto max-w-[330px] w-full">
                            <p className="mb-2 md:mb-4 !text-sm md:!text-base font-inter-16-regular">BARBEQUE NATION</p>
                            <h3 className="text-xl md:text-[39px] md:leading-[46.8px] font-poppins font-normal md:text-wireframe-heading mb-5 md:mb-8">Dine Out With Barbeque Nation</h3>
                            <Button id="service-reserve-btn" variant="primary" className="group" rightIcon={<Icon name="rightArrowWhite" className="group-hover:fill-primary-5 fill-white h-[17px]" />}
                                onClick={() => {
                                    if (!selectedBranch) {
                                        toast.warning('Please select a location!')
                                        return
                                    }
                                    dispatch(reservationSidbarOpenPopup(true))
                                }}>
                                Reserve Table
                            </Button>
                        </div>
                    </div>
                    <div className="bg-primary-1 order-3 md:order-none rounded-xl flex p-4">
                        <div className="md:m-auto max-w-[330px] w-full">
                            <p className="mb-2 md:mb-4 !text-sm md:!text-base font-inter-16-regular">ATMOSFIRE</p>
                            <h3 className="text-xl md:text-[39px] md:leading-[46.8px] font-poppins font-normal md:text-wireframe-heading mb-5 md:mb-8">Catering By Barbeque Nation</h3>
                            <Button href="/catering"
                                variant="primary"
                                className="group w-fit"
                                rightIcon={<Icon name="rightArrowWhite" className="group-hover:fill-primary-5 fill-white h-[17px] mt-1" />}
                            >
                                Send Enquiry
                            </Button>
                        </div>
                    </div>
                    <Image loading='lazy' src="/images/catering-employees.jpeg" alt="error" width={585} height={390} className="block rounded-xl w-full h-full object-cover" />
                </div>
            </div>
            <div className="md:hidden block pt-6 px-5">
                <SliderSection />
            </div>
        </section>
    )
}
export default Services;