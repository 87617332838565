"use client"
import React, { useEffect, useState } from 'react';
import Icon from '@/components/Icon';
import { DropdownMenu } from '@/components/ui/DropdownMenu';
import Location from '@/components/Location';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '@/store/store';
import { reservationSidbarOpenPopup } from '@/store/slices/auth/signPopupSlice';
import { toast } from 'react-toastify';


const ReserveTableBar: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    // Destructuring the branches state from Redux
    const { data: branches, loadingNearbyBranches: loading, errorNearbyBranches: error } = useSelector(
        (state: AppState) => state.branches
    );
    const getSavedRecords = useSelector((state: AppState) => state.branches.saved);

    // Local state management
    const [isOpenLocation, setIsOpenLocation] = useState(false);
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedPlace, setSelectedPlace] = useState('');
    const [Locations, setLocations] = useState<any[]>([]);
    const [errorHanle, setErrorHanle] = useState(false);

    /**
     * Handles the location option click event.
     * @param {string} city - The selected city name.
     * @param {string} place - The selected place name.
     */
    const handleLocationOptionClick = (city: string, place: string) => {
        setSelectedCity(city);
        setSelectedPlace(place);
        setIsOpenLocation(false);
        setErrorHanle(false);
    };

    // Set locations from branches data when branches data changes
    useEffect(() => {
        if (branches) setLocations(branches?.data);
    }, [branches]);

    // Set city and place state from saved records when saved records change
    useEffect(() => {
        if (getSavedRecords) setCityState();
    }, [getSavedRecords]);

    /**
     * Sets the city and place state from local storage.
     */
    const setCityState = () => {
        try {
            if (typeof window === 'undefined') return;
            let getData = localStorage.getItem('bwf');
            if (!getData) return;
            let finalData = JSON.parse(getData) as { city_name: string, branch_name: string, details: any };
            setSelectedCity(finalData.city_name);
            setSelectedPlace(finalData.branch_name);
        } catch (error) {
            console.error("Error loading city state from local storage", error);
        }
    };

    /**
     * Handles the reserve table button click event.
     * If no location is selected, displays an error message.
     */
    const handleReserveTableClick = () => {
        if (!selectedCity && !selectedPlace) {
            toast.error("Please select a location!");
            setErrorHanle(true);  // Add shake effect
            setTimeout(() => setErrorHanle(false), 300);
            return;
        }
        // Open the sidebar if a location is selected
        dispatch(reservationSidbarOpenPopup(true))
    };

    return (
        <div
            role="region"
            aria-labelledby="reserve-table-heading"
            className={`animate-fade-up p-5 lg:p-6 bg-white shadow-shadows1 rounded-xl flex gap-2 md:gap-[25px] mt-1 md:mt-9 max-w-full lg:max-w-[768px] mx-auto flex-col lg:flex-row justify-between border border-gray-2/90 ${errorHanle ? 'shake' : ''
                }`}
        >
            <h2 id="reserve-table-heading" className="sr-only">Reserve a Table</h2> {/* Hidden heading for screen readers */}
            <div className='flex lg:gap-10 items-center flex-col lg:flex-row justify-between lg:max-w-[450px] w-full'>
                {/* Location selection */}
                <div className='flex items-center pb-4 mb-4 lg:pb-0 lg:mb-0 lg:border-b-0 border-b border-gray-2 gap-10 relative w-full lg:w-full'>
                    <DropdownMenu
                        trigger={
                            <button
                                aria-haspopup="true"
                                aria-expanded={isOpenLocation}
                                aria-label="Select a restaurant location"
                                className='flex items-center gap-5 justify-between w-full overflow-hidden '
                                onClick={() => setIsOpenLocation(!isOpenLocation)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        e.preventDefault();
                                        setIsOpenLocation(!isOpenLocation);
                                    }
                                }}
                            >
                                <div>
                                    <span className='block mb-3 text-wireframe-grey font-inter text-sm leading-[22.4px] text-left whitespace-nowrap'>
                                        Select Restaurant
                                    </span>
                                    <div className='text-gray-6 font-semibold text-[19px] font-inter leading-6 text-left whitespace-nowrap overflow-hidden max-w-full text-ellipsis'>
                                        {selectedCity ? `${selectedPlace} , ${selectedCity}` : 'Select Restaurant'}
                                    </div>
                                </div>
                                <div className='hidden lg:block'>
                                    <Icon name='arrowDownOrange' />
                                </div>
                            </button>
                        }
                        isOpen={isOpenLocation}
                        onToggle={() => setIsOpenLocation(!isOpenLocation)}
                        containerClassName='w-full block'
                        className='w-full'
                    >
                        {(closeMenu) => (
                            <Location
                                options={Locations}
                                onOptionClick={(city, place) => {
                                    handleLocationOptionClick(city, place);
                                    closeMenu();
                                }}
                            />
                        )}
                    </DropdownMenu>
                </div>
            </div>
            <div className='lg:min-w-[220px]'>
                <button
                    id='home-reserve-main'
                    aria-live="assertive"
                    aria-describedby="reserve-error"
                    className={`w-full py-2 md:py-3 lg:py-3 px-8 rounded-lg bg-primary-5 inline-flex items-center gap-2.5 md:gap-4 justify-center flex-nowrap hover:bg-transparent group duration-300 border-[1.5px] border-transparent hover:border-primary-5 shadow-[0px_8px_40px_0px_#E9560880] hover:shadow-none ${errorHanle ? 'shake' : ''
                        }`}
                    onClick={handleReserveTableClick}
                >
                    <Icon name='food' size={28} className='group-hover:stroke-primary-5' />
                    <span className='text-white font-inter font-semibold leading-[19.2px] group-hover:text-primary-5 whitespace-nowrap'>
                        Reserve Table
                    </span>
                </button>
            </div>
        </div>
    );
};

export default ReserveTableBar;
