"use client"
import Icon from '@/components/Icon';
import Image from 'next/image';
import React from 'react'
import Link from 'next/link';
import { reservationSidbarOpenPopup } from '@/store/slices/auth/signPopupSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@/store/store';
import { toast } from 'react-toastify';

const OurServices = () => {
    const dispatch = useDispatch();
    const selectedBranch = useSelector((state: AppState) => state.branches.saved);

    // Background colors of cards
    const styles = {
        firstCard: {
            backgroundColor: '#FFE8E0',
        },
        secondCard: {
            backgroundColor: '#DBF8FF',
        },
        thirdCard: {
            backgroundColor: '#E1FFBA',
        },
        fourthCard: {
            backgroundColor: '#FFE8C5',
        },
    };

    const CardsObject1 = [
        {
            image: "/images/card1.png",
            name: "Reserve Table",
            action: "reserveTable",
            bgColor: styles.firstCard,
        },
        {
            image: "/images/card3.png",
            name: "Happiness Cards",
            link: "/vouchers",
            bgColor: styles.thirdCard,
        },

        {
            image: "/images/card4.png",
            name: "Catering",
            link: "/catering",
            bgColor: styles.fourthCard,
        },
        {
            image: "/images/card2.png",
            name: "Delivery/ Takeaway",
            link: "/ubq-delivery",
            bgColor: styles.secondCard,
        },
    ];
    const handleCardClick = (action: string | undefined) => {
        if (action === "reserveTable") {
            if (!selectedBranch) {
                toast.warning("Please select a location!");
                return;
            }
            dispatch(reservationSidbarOpenPopup(true));
        }
    };
    return (
        <ul className="grid grid-cols-4 lg:gap-[30px] gap-[13px] mt-8 md:mt-[60px] lg:mb-[-160px]">
            {CardsObject1.map((item, index) => (
                <li
                    key={index}
                    className="md:p-5 p-2 rounded-xl bg-[#FFE8E0] group cursor-pointer"
                    style={item.bgColor}
                >
                    {item.link ? (
                        <Link href={item.link} className="flex flex-col items-center justify-center">
                            <Image
                                src={item.image}
                                alt="barbeque Nation"
                                className="mx-auto group-hover:scale-105 duration-300 md:max-w-[160px] w-full max-w-[42px]"
                                width={160}
                                height={160}
                            />
                            <div className='flex justify-between gap-2 items-center'>
                                <span className="font-inter-16-regular max-[767px]:text-[12px] !text-gray-6 block lg:inline max-[1023px]:text-center">
                                    {item.name}
                                </span>
                                <Icon name="rightArrow" className="mt-1 hidden md:block" />
                            </div>
                        </Link>
                    ) : (
                        <div
                            className="flex flex-col items-center justify-center"
                            onClick={() => handleCardClick(item.action)}
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleCardClick(item.action);
                            }}
                        >
                            <Image
                                src={item.image}
                                alt="barbeque Nation"
                                className="mx-auto group-hover:scale-105 duration-300 md:max-w-[160px] w-full max-w-[42px]"
                                width={160}
                                height={160}
                            />
                            <div className='flex justify-between gap-2 items-center'>
                                <span className="font-inter-16-regular max-[767px]:text-[12px] !text-gray-6 block lg:inline max-[1023px]:text-center">
                                    {item.name}
                                </span>
                                <Icon name="rightArrow" className="mt-1 hidden md:block" />
                            </div>
                        </div>
                    )}
                </li>
            ))}
        </ul>
    )
}

export default OurServices