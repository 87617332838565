"use client";
import { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import Icon from "@/components/Icon";
import Nationals from "./locations/Nationals";
// import Internationals from "./locations/Internationals";

const OurRestorents = () => {
    const [show, setShow] = useState(1);
    const prevRef = useRef<HTMLButtonElement>(null);
    const nextRef = useRef<HTMLButtonElement>(null);

    return (
        <section className="md:py-[25px] py-6 relative">
            <div className="container">
                <div className="flex items-center justify-between mb-4">
                    <h2 className="font-poppins-32-regular !font-medium">Our Restaurants</h2>
                    <div className="md:flex hidden items-center gap-[12px]">
                        <button className="w-[52px] h-[52px] rotate-[-180deg]" ref={prevRef}>
                            <Icon name="sliderRight" className="w-full h-full" />
                        </button>
                        <button className="w-[52px] h-[52px]" ref={nextRef}>
                            <Icon name="sliderRight" className="w-full h-full" />
                        </button>
                    </div>
                </div>

                {/* <div className="inline-flex mb-10 items-center p-[10px] max-w-[412px] w-full bg-gray-1 rounded-lg">
                    <button onClick={() => { setShow(1) }} className={` !border ${show == 1 ? 'bg-white shadow-[12px_0_24px_#0000000A] !border-gray-2 text-wireframe-heading' : 'text-gray-5 border-transparent'}   duration-300 leading-[22.4px] font-inter px-3 py-2 text-base font-normal rounded-lg w-full`}>Domestic</button>
                    <button onClick={() => { setShow(2) }} className={` !border ${show == 2 ? 'bg-white shadow-[12px_0_24px_#0000000A] !border-gray-2 text-wireframe-heading' : 'text-gray-5 border-transparent'}  duration-300 leading-[22.4px] font-inter px-3 py-2 text-base font-normal rounded-lg w-full`}>International</button>
                </div> */}

                {
                    show == 1 &&
                    <Nationals prevRef={prevRef} nextRef={nextRef} />
                }
                {/* {
                    show == 2 &&
                    <Internationals prevRef={prevRef} nextRef={nextRef} />
                } */}
            </div>
        </section>
    )
}
export default OurRestorents;