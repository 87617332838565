"use client";
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import dynamic from "next/dynamic"; // Use dynamic import

// Dynamically import non-essential components
const Sidebar = dynamic(() => import('../sidebars/Sidebar'), { ssr: false });
const Cart = dynamic(() => import('../sidebars/cartSidebar/Cart'), { ssr: false });
const Login = dynamic(() => import('../login-signup/Login'), { ssr: false });
const SignUp = dynamic(() => import('../login-signup/SignUp'), { ssr: false });
const ReservationTableSidebar = dynamic(() => import('../sidebars/reservationSidebar/ReservationTableSidebar'), { ssr: false });
const ProfileDropdown = dynamic(() => import('./ProfileDropdown'), { ssr: false });
const Icon = dynamic(() => import('../Icon'), { ssr: false }); // Dynamic icon loading


// import Icon from '../Icon';
import LocationDropdown from './LocationDropdown';
// import Sidebar from '../sidebars/Sidebar';
import HeaderLinks from './HeaderLinks';
// import Cart from '../sidebars/cartSidebar/Cart';
// import Login from '../login-signup/Login';
// import SignUp from '../login-signup/SignUp';
import useModal from '@/hooks/useModal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '@/store/store';
import { signOut, useSession } from 'next-auth/react';
import { fetchHappinessCart } from '@/store/slices/happiness-card/happinessCartSlice';
import { selectOrderItemsLength } from '@/store/slices/happiness-card/happinessCartSlice';
import { fetchSmile } from '@/store/slices/table_booking_flow/smileSlice';
// import ReservationTableSidebar from '../sidebars/reservationSidebar/ReservationTableSidebar';
import useGeolocation from '@/hooks/useGeolocation';
import useViewportWidth from '@/hooks/useViewportWidth';
import Script from 'next/script';
import { useGTM } from '@/hooks/useGTM';
import Loader from '../Loader';
import { fetchUserDetails } from '@/store/slices/users/user.slice';
import { getCookie, setCookie } from '@/lib/utils';
import { usePathname } from 'next/navigation';
// import ProfileDropdown from './ProfileDropdown';

/**
 * The Header component for the main navigation.
 * It contains the logo, navigation links, user profile, cart, and other controls.
 * @returns {JSX.Element} The rendered header component.
 */



const Header = () => {
  const { location, requestLocation } = useGeolocation();
  const dispatch = useDispatch<AppDispatch>();
  const { data, loading, error } = useSelector((state: AppState) => state.happinessCart);
  const { user } = useSelector((state: AppState) => state.user);
  const selectedBranch = useSelector((state: AppState) => state.branches.saved);
  const totalOrderItems = useSelector(selectOrderItemsLength);
  const { data: session } = useSession();
  const authPopup = useSelector((state: AppState) => state.authPopup.value);
  const signUpPopup = useSelector((state: AppState) => state.authPopup.signUp);
  const { closeModal, isVisible, openModal } = useModal();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isCartSidebarOpen, setIsCartSidebarOpen] = useState(false);
  const [authStep, setAuthStep] = useState(0); // 0: None, 1: Login, 2: Sign Up
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const viewportWidth = useViewportWidth();
  const shouldLoadGTM = useGTM();
  const pathname = usePathname();
  useEffect(() => {
    // Set mobile view state
    setIsMobileView(viewportWidth < 768);
    // Load Google Tag Manager on body interaction (e.g., scroll or hover)
  }, [viewportWidth]);

  // Open modal or toggle dropdown based on user login status
  const handleAvatarClick = () => {
    if (session?.user?.name) {
      if (isMobileView) {
        setDropdownVisible((prev) => !prev); // Toggle dropdown on click for mobile
      }
    } else {
      openModal(); // Open login modal
      setAuthStep(1); // Set login step
    }
  };

  // Handles mouse enter event to show the dropdown on desktop
  const handleMouseEnter = () => {
    if (!isMobileView && session?.user?.name) {
      setDropdownVisible(true); // Show dropdown on hover for desktop
    }
  };

  // Handles mouse leave event to hide the dropdown on desktop
  const handleMouseLeave = () => {
    if (!isMobileView && session?.user?.name) {
      setDropdownVisible(false); // Hide dropdown on hover for desktop
    }
  };


  useEffect(() => {
    if (authPopup) {
      setAuthStep(1); // Open Login Modal
      openModal();
    }
    if (signUpPopup) {
      setAuthStep(2); // Open Login Modal
      openModal();
    }
  }, [authPopup, signUpPopup, openModal]);

  /**
   * Handles sign-in or sign-out.
   * If the user is authenticated, it triggers the sign-out. Otherwise, it opens the login modal.
   */
  const handleSignInOrOut = () => {
    if (session?.user?.name) {
      signOut();
    } else {
      openModal();
      setAuthStep(1);
    }
  };

  /**
   * Fetches the happiness cart when the component is first mounted.
   */
  useEffect(() => {
    dispatch(fetchHappinessCart({ session: session ? true : false }))

    if (getCookie('load_users') == undefined && getCookie('load_users') == null && session) { // reload data every 1 hrs
      setCookie('load_users', '1', 1)
      session && dispatch(fetchUserDetails({ session: session ? true : false }));
    } else if (user == null) {
      session && dispatch(fetchUserDetails({ session: session ? true : false }));
    }

  }, [dispatch, session]);


  useEffect(() => {
    if (window) {
      navigator?.permissions?.query({ name: 'geolocation' }).then((permissionStatus) => {
        if (permissionStatus.state != "denied") {
          setTimeout(() => {
            if (!selectedBranch)
              requestLocation()
          }, 2000);
        }
      })
    }
  }, [requestLocation]);

  /**
   * Fetches the branch details if a branch is selected.
   */
  // useEffect(() => {
  //   if (selectedBranch) {
  //     if (window && !window.location.pathname.includes('/restaurants/')) {
  //       dispatch(fetchBranchDetails({ branch_id: selectedBranch?.details?.branch_id }));
  //     }
  //   }
  // }, [selectedBranch]);
  /**
   * Fetches smile points if the user session is active.
   */
  useEffect(() => {

    if (session) {
      session && dispatch(fetchSmile());
    }
  }, [session]);

  return (
    <>
      <header className="py-[14px] max-[767px]:rounded-b-[16px] bg-white md:bg-primary-1 md:py-6 z-[51] shadow-sm fixed top-0 left-0 w-full transition-transform duration-300 ease-in-out">

        {/* <a onClick={requestLocation}> loaction </a> */}
        <div className="container mx-auto flex justify-between items-center">
          {/* Logo and Navigation Links */}
          <div className="flex items-center space-x-[30px]">
            <Link href="/">
              <Image
                src="/icons/logo.svg"
                alt="Barbeque Nation"
                width={73}
                height={46}
                loading="lazy"
                className="md:block hidden"
              />
            </Link>
            <nav className="min-[1175px]:flex hidden space-x-6">
              <Link href="/vouchers" className="text-sm font-inter text-wireframe-heading hover:text-primary-5 leading-[22.4px] duration-300">
                Happiness card
              </Link>
              <Link href="/promotion" className="text-sm font-inter text-wireframe-heading hover:text-primary-5 leading-[22.4px] duration-300">
                Whats on BBQ
              </Link>
              <Link href="/restaurants" className="text-sm font-inter text-wireframe-heading hover:text-primary-5 leading-[22.4px] duration-300">
                Restaurants
              </Link>
              <Link href="/catering" className="text-sm font-inter text-wireframe-heading hover:text-primary-5 leading-[22.4px] duration-300">
                Catering
                <span className="ml-0.5 text-[10px] font-inter text-white px-1 py-0.5 rounded-sm bg-[#66B205]">New</span>
              </Link>
            </nav>
          </div>

          {/* Right Side Controls */}
          <div className="flex items-center max-[767px]:justify-between max-[767px]:w-full md:gap-3">
            <div className="hidden gap-4 md:flex">
              <div className="relative">
                {/* Avatar Button and Dropdown Container */}
                <div
                  className="relative flex items-center"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {/* Avatar Button */}
                  <button
                    className="text-gray-800 relative group hover:pb-4 hover:-mb-4 "
                    onClick={handleAvatarClick}
                    aria-label={session?.user.name ? `Open profile for ${session?.user.name}` : "Open user profile"}
                  >
                    {(session?.user.name) ? (
                      <div className="w-6 h-6 rounded-full overflow-hidden">
                        <Image loading='lazy' src="/icons/user-avtar.svg" alt={session?.user.name ? `Avatar of ${session?.user.name}` : "Default user avatar"}
                          width={24} height={24} className="rounded-full" />
                      </div>
                    ) : (
                      <Icon name="user" />
                    )}
                    <span className='min-w-32 h-5  left-1/2 -translate-x-1/2 absolute top-6 -z-10 bg-black hidden opacity-0 group-hover:block'></span>
                  </button>

                  <ProfileDropdown
                    isVisible={isDropdownVisible}
                    onSignOut={handleSignInOrOut}
                    onClose={() => setDropdownVisible(false)}
                  />
                </div>
              </div>
              {/* Notification Button */}
              {/* <button className="text-gray-800 md:block hidden">
                <Icon name="bell" />
              </button> */}
              {/* Cart Button */}
              <button id='hapiness-cart-btn' className="text-gray-800 md:block hidden relative" onClick={() => setIsCartSidebarOpen(true)}>
                {loading && (
                  <span className="item-count absolute top-[-10px] text-xs right-[-10px] bg-primary-5 text-white h-[20px] w-[20px] rounded-[50%]">
                    <Loader />
                  </span>
                )}
                {totalOrderItems > 0 && <span className="item-count absolute top-[-10px] text-xs right-[-10px] bg-primary-5 text-white h-[20px] w-[20px] rounded-[50%]">{totalOrderItems}</span>}
                <Icon name="lock" />
              </button>
            </div>

            <div className="flex gap-3 justify-between items-center w-full md:justify-normal md:w-auto">
              <Link href="/" className='block md:hidden'>
                <Image loading='lazy' src="/icons/bbq-logo-mob.svg" alt='Barbeque Logo' width={40} height={36} />
              </Link>
              <Link href="/ubq-delivery" className="text-primary-6 md:flex hidden font-inter text-sm items-center gap-2 px-3 py-2.5 bg-primary-2 rounded-lg">
                <Icon name="deliveryBox" />
                Delivery/Takeaway
              </Link>
              <div className="flex items-center gap-3">
                {
                  (pathname !== "/ubq-delivery" && pathname !== "/dumsafar-delivery") && <LocationDropdown />
                }
                {/* Mobile User Button */}
                <div className='relative md:hidden'>
                  <button
                    className="text-gray-800 relative group hover:pb-4 hover:-mb-4 block md:hidden"
                    onClick={handleAvatarClick}
                    aria-label={session?.user.name ? `Open profile for ${session?.user.name}` : "Open user profile"}
                  >
                    {session?.user.name ? (
                      <div className="w-6 h-6 rounded-full overflow-hidden">
                        {/* Display user avatar or fallback to default avatar */}
                        <Image loading='lazy' src="/icons/user-avtar.svg" alt={session?.user.name ? `Avatar of ${session?.user.name}` : "Default user avatar"}
                          width={24} height={24} className="rounded-full" />
                      </div>
                    ) : (
                      <Icon name="user" />
                    )}
                    <span className='min-w-32 h-5  left-1/2 -translate-x-1/2 absolute top-6 -z-10 bg-black hidden opacity-0 group-hover:block'></span>
                  </button>
                  <ProfileDropdown
                    isVisible={isDropdownVisible}
                    onSignOut={handleSignInOrOut}
                    onClose={() => setDropdownVisible(false)}
                    className="-left-[85%] w-[210px]"
                  />
                </div>
                {
                  selectedBranch && selectedBranch !== null && selectedBranch !== undefined &&
                  <Link href={`${selectedBranch?.details?.branch_alias}`} className="size-11 hidden md:flex items-center justify-center md:p-2 md:bg-primary-2 rounded-lg text-sm font-inter text-wireframe-heading hover:text-primary-5 leading-[22.4px] duration-300">
                    <Icon name='infoOutlet' />
                  </Link>
                }
                {/* Mobile Cart Button */}
                <button id='hapiness-cart-btn' className="text-gray-800 md:hidden block relative" onClick={() => setIsCartSidebarOpen(true)}>
                  {loading && (
                    <span className="item-count absolute top-[-10px] text-xs right-[-10px] bg-primary-5 text-white h-[20px] w-[20px] rounded-[50%]">
                      <Loader />
                    </span>
                  )}
                  {totalOrderItems > 0 && <span className="item-count absolute top-[-10px] text-xs right-[-10px] bg-primary-5 text-white h-[20px] w-[20px] rounded-[50%]">{totalOrderItems}</span>}
                  <Icon name="lock" />
                </button>
                {/* Hamburger Menu for Sidebar */}
                <button className="flex items-center justify-center md:p-2 md:bg-primary-2 rounded-lg" onClick={() => setIsSidebarOpen(true)} aria-label="Open sidebar menu">
                  <Icon name="Hamburgur" className="w-8 h-7 md:hidden block" color="#000" />
                  <Icon name="Hamburgur" className="w-8 h-7 md:block hidden" color="#EE4824" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Sidebars */}
      <Sidebar
        isOpen={isSidebarOpen}
        className="max-w-[663px] md:p-3"
        onClose={() => setIsSidebarOpen(false)}
        heading={<Image src="/icons/logo.svg" width={51} height={32} alt="logo" />}
      >
        <HeaderLinks />
      </Sidebar>

      {/* Cart Sidebar */}
      <Cart isCartSidebarOpen={isCartSidebarOpen} closeCartSidebar={() => setIsCartSidebarOpen(false)} />

      {/* Modals for Authentication */}
      {authStep === 1 && <Login setStep={setAuthStep} isOpen={isVisible} onClose={closeModal} />}
      {authStep === 2 && <SignUp setStep={setAuthStep} isOpen={isVisible} onClose={closeModal} />}

      {/*Table Reservation Sidebar  */}
      <ReservationTableSidebar />

      {/* Conditionally load the GTM script after user interaction */}
      {shouldLoadGTM && (
        <Script
          id="gtm-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-TF3NNN');
            `,
          }}
        />
      )}
    </>
  );
};

export default Header;
