import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { RefObject } from "react";
import { AppState } from "@/store/store";
import { useSelector } from "react-redux";
import Link from "next/link";
import Image from "next/image";

interface Locations {
    prevRef?: RefObject<HTMLButtonElement>;
    nextRef?: RefObject<HTMLButtonElement>;
}

const Nationals: React.FC<Locations> = ({ prevRef, nextRef }) => {
    const { data: branches, loadingNearbyBranches: loading, errorNearbyBranches: error } = useSelector(
        (state: AppState) => state.branches
    );
    const fallbackBanner = "/images/BarbequeFallback.png";

    return (
        <>
            {/* Swiper for Desktop */}
            <Swiper
                modules={[Navigation]}
                slidesPerView={4}
                spaceBetween={30}
                navigation={{
                    prevEl: prevRef?.current,
                    nextEl: nextRef?.current,
                }}
                onSwiper={(swiper) => {
                    if (swiper?.params?.navigation) {
                        const navigation = swiper.params.navigation;
                        if (typeof navigation !== "boolean") {
                            navigation.prevEl = prevRef?.current;
                            navigation.nextEl = nextRef?.current;
                            swiper.navigation.destroy();
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }
                    }
                }}
                breakpoints={{
                    300: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                }}
                className="tmbMySwiper md:!block !hidden !p-1"
            >
                {branches?.data?.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                        <Link
                            href={`/restaurants/${item.city_alias
                                ?.replace(/\s+/g, "-")
                                .toLowerCase()}`}
                            className="bg-white rounded-lg overflow-hidden shadow relative block"
                        >
                            <Image
                                src={item?.city_icon || fallbackBanner}
                                alt={item.city_name}
                                width={400}
                                height={200}
                                loading="lazy"
                                className="w-full h-40 object-cover"
                            />
                            {item?.branches?.length && (
                                <span className="absolute top-2 right-2 font-inter text-xs font-medium text-white bg-black/60 py-1 px-2 rounded-full">
                                    {item?.branches?.length} {item?.branches?.length === 1 ? 'Branch' : 'Branches'}
                                </span>
                            )}
                            <div className="p-4">
                                <h3 className="text-lg font-semibold">{item.city_name}</h3>
                            </div>
                        </Link>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Card Grid for Mobile */}
            <div className="md:hidden block">
                <ul className="grid md:grid-cols-4 grid-cols-2 md:gap-[30px] gap-[16px]">
                    {branches?.data?.slice(0, 4)?.map((item: any, index: number) => (
                        <li key={index}>
                            <Link
                                href={`/restaurants/${item.city_alias
                                    ?.replace(/\s+/g, "-")
                                    .toLowerCase()}`}
                                className="bg-white rounded-lg overflow-hidden shadow relative block"
                            >
                                <Image
                                    src={item?.city_icon || fallbackBanner}
                                    alt={item.city_name}
                                    width={400}
                                    height={400}
                                    loading="lazy"
                                    className="w-full aspect-square object-cover h-28"
                                />
                                {item?.branches?.length && (
                                    <span className="absolute top-2 right-2 font-inter text-xs font-medium text-white bg-black/60 py-1 px-2 rounded-full">
                                        {item?.branches?.length} {item?.branches?.length === 1 ? 'Branch' : 'Branches'}
                                    </span>
                                )}
                                <div className="p-2 md:p-4">
                                    <h3 className="text-lg font-semibold">{item.city_name}</h3>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className="flex justify-center items-center w-full pt-4 md:hidden">
                    <Link
                        href="/restaurants"
                        className="px-[36px] py-[12px] justify-center font-inter text-base font-normal rounded-lg border duration-300 leading-[22.4px] bg-transparent text-primary-5 border-primary-5 hover:bg-primary-5 hover:text-white hover:shadow-shadow-btn flex items-center group max-[767px]:py-[7px]"
                    >
                        View More
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Nationals;
