"use client";

import Button from "@/components/ui/Button";
import { FC } from "react";
import { useSelector } from "react-redux";
import { AppState } from "@/store/store";
import Skeleton from "react-loading-skeleton";
import { cn } from "@/lib/utils";

interface FixCartBarProps {
  onButtonClick: () => void;
  className?: string;
}

const FixCartBar: FC<FixCartBarProps> = ({ onButtonClick, className }) => {
  const cartData = useSelector((state: AppState) => state.happinessCart.data);
  const loading = useSelector((state: AppState) => state.happinessCart.loading);
  const cartEmpty = !cartData || cartData?.order_items?.length === 0;

  const totalItems = cartData?.order_items?.length || 0;
  const totalPrice = cartData?.total_price || 0;
  // const currency = cartData?.currency_code || "INR";

  return (
    <>
      {
        !cartEmpty &&
        <section className={cn("fixed bottom-0 left-0 w-full bg-wireframe-heading py-2 md:py-4 z-[2] shadow-lg h-16 md:h-20 animate-slide-up-fade cursor-pointer", className)} onClick={onButtonClick}>
          <div className="container flex items-center justify-between">
            <div className="flex items-center gap-4">
              {loading ? (
                <div>
                  <Skeleton width={80} height={20} className="mb-1" />
                  <Skeleton width={100} height={14} />
                </div>
              ) : cartEmpty ? (
                <p className="font-inter text-sm font-semibold text-white">
                  Your cart is empty.
                  <br />
                  Add items to place an order.
                </p>
              ) : (
                <div>
                  <p className="font-inter text-lg font-semibold text-white">
                    ₹{totalPrice.toLocaleString()}
                  </p>
                  <p className="font-inter text-sm text-gray-200">
                    {totalItems} item{totalItems > 1 ? 's' : ''} in cart
                  </p>
                </div>
              )}
            </div>
            <Button
              id={`hapiness-bottombar-proceed-btn`}
              variant="primary"
              onClick={onButtonClick}
              disabled={cartEmpty || loading}
              className="text-sm font-semibold px-4 py-2"
            >
              Proceed to Checkout
            </Button>
          </div>
        </section>
      }
    </>
  );
};

export default FixCartBar;
