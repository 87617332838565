import { setGeoLocation } from '@/store/slices/visitorInfo/visitorInfoSlice';
import { AppDispatch } from '@/store/store';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

type LocationState = {
  latitude: number | null;
  longitude: number | null;
  geoLoading: boolean;
  geoError: string | null;
  isPermissionDenied: boolean;
};

const useGeolocation = (): {
  location: LocationState;
  requestLocation: () => Promise<void>;
} => {

  const dispatch = useDispatch<AppDispatch>();

  const [location, setLocation] = useState<LocationState>({
    latitude: null,
    longitude: null,
    geoLoading: false,
    geoError: null,
    isPermissionDenied: false,
  });

  const requestLocation = useCallback((): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        setLocation((prevState) => ({
          ...prevState,
          geoLoading: false,
          geoError: 'Geolocation is not supported by this browser.',
          isPermissionDenied: false,
        }));
        return reject('Geolocation is not supported by this browser.');
      }

      setLocation((prevState) => ({
        ...prevState,
        geoLoading: true,
        isPermissionDenied: false,
      }));

      const onSuccess = (position: GeolocationPosition) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          geoLoading: false,
          geoError: null,
          isPermissionDenied: false,
        });

        dispatch(setGeoLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude }));

        resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      };

      const onError = (error: GeolocationPositionError) => {
        if (error.code === error.PERMISSION_DENIED) {
          setLocation((prevState) => ({
            ...prevState,
            geoLoading: false,
            geoError: 'Location access denied by user.',
            isPermissionDenied: true,
          }));

          if (navigator.userAgent.indexOf("Chrome-Lighthouse") > -1) {
            return false;
          }

          toast.warn('Please allow location access through your browser settings.');
          reject('Location access denied by user.');
        } else {
          setLocation((prevState) => ({
            ...prevState,
            geoLoading: false,
            geoError: error.message,
            isPermissionDenied: false,
          }));
          reject(error.message);
        }
      };



      if (navigator.permissions) {
        navigator.permissions
          .query({ name: 'geolocation' })
          .then((permissionStatus) => {
            if (permissionStatus.state === 'denied') {
              const errorMsg = 'Location access is blocked in your browser settings.';
              setLocation((prevState) => ({
                ...prevState,
                geoLoading: false,
                geoError: errorMsg,
                isPermissionDenied: true,
              }));
              toast.warn(errorMsg);
              return reject(errorMsg);
            } else {
              navigator.geolocation.getCurrentPosition(onSuccess, onError, {
                enableHighAccuracy: true,
                timeout: 7000,
                maximumAge: 0,
              });
            }
          });
      }
    });
  }, [dispatch]);

  useEffect(() => {
    // Uncomment to request location on mount
    // requestLocation();
  }, [requestLocation]);

  return { location, requestLocation };
};

export default useGeolocation;


