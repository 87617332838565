import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CartState, CartData } from '@/types/next';
import { toast } from 'react-toastify';

/**
 * The initial state for the happiness cart slice.
 * @typedef {Object} CartState
 * @property {CartData | null} data - The cart data.
 * @property {string | null} error - Error message, if any.
 * @property {boolean} loading - Loading state for the cart.
 * @property {number} loyalty_points - Loyalty points earned by the user.
 */
const initialState: CartState = {
  data: null,
  error: null,
  loading: false,
  loyalty_points: 0,
};

/**
 * Async thunk for fetching the happiness cart.
 * Depending on the user's session, the appropriate API endpoint is called.
 *
 * @async
 * @function fetchHappinessCart
 * @param {Object} params - Parameters for the thunk.
 * @param {boolean} params.session - Boolean indicating if the user is logged in.
 * @returns {Promise<CartData>} The cart data.
 * @throws Will throw an error if the API call fails.
 */
export const fetchHappinessCart = createAsyncThunk(
  'happinessCart/fetchHappinessCart',
  async ({ session }: { session: boolean }, { rejectWithValue }) => {
    try {

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      // myHeaders.append('BBQ-Client-Id', process.env.BBQ_Client_Id || '');
      // myHeaders.append('BBQ-Client-Secret', process.env.BBQ_Client_Secret || '');

      let url = '';
      if (session) {
        url = "/api/v1/cart"; // Logged in user API endpoint
      } else {
        // If not logged in, fetch cart by order_id stored in localStorage
        return false;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow" as RequestRedirect,
      });

      if (!response.ok) {
        const errorData = await response.json();
        if(errorData?.error.includes('Blocked by Cloudflare'))
           toast.warn(`${errorData?.error}`)
          
        return rejectWithValue(errorData);
      }


      const result = await response.json();
      return result?.results as CartData;
    } catch (error: any) {
      toast.warn(error)
       

      return rejectWithValue(error.message);
    }
  }
);


export const fetchDeleteHappinessCart = createAsyncThunk(
  'happinessCart/fetchDeleteHappinessCart',
  async ({ session, url }: { session: boolean, url: string }, { rejectWithValue }) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      // myHeaders.append('BBQ-Client-Id', process.env.BBQ_Client_Id || '');
      // myHeaders.append('BBQ-Client-Secret', process.env.BBQ_Client_Secret || '');

      const response = await fetch(url, {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow" as RequestRedirect,
        next: { revalidate: 1800 }
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData);
      }

      const result = await response.json();
      return result?.results as CartData;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Slice for managing the happiness cart state.
 * It includes actions and extra reducers for handling asynchronous fetches.
 */
const happinessCartSlice = createSlice({
  name: 'happinessCart',
  initialState,
  reducers: {
    /**
     * Reducer to update loyalty points in the cart state.
     *
     * @param {CartState} state - The current state of the cart.
     * @param {PayloadAction<number>} action - The action containing the number of loyalty points.
     */
    happiness_cart_loyalty_points: (state, action: PayloadAction<number>) => {
      state.loyalty_points = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Handles the pending state when the fetchHappinessCart thunk is triggered.
       *
       * @param {CartState} state - The current state of the cart.
       */
      .addCase(fetchHappinessCart.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      /**
       * Handles the fulfilled state when the happiness cart data is successfully fetched.
       *
       * @param {CartState} state - The current state of the cart.
       * @param {PayloadAction<CartData>} action - The action containing the fetched cart data.
       */
      .addCase(fetchHappinessCart.fulfilled, (state, action: PayloadAction<CartData | false>) => {
        state.loading = false;
        if (action.payload) {
          state.data = action.payload as CartData; // Set cart data if available
        } else {
          state.data = null; // No cart data (false case)
        }
        // state.data = action.payload;
      })

      /**
       * Handles the rejected state when fetching the happiness cart fails.
       *
       * @param {CartState} state - The current state of the cart.
       * @param {PayloadAction<any>} action - The action containing the error message.
       */
      .addCase(fetchHappinessCart.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


      /**
 * Handles the pending state when the fetchHappinessCart thunk is triggered.
 *
 * @param {CartState} state - The current state of the cart.
 */
      .addCase(fetchDeleteHappinessCart.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      /**
       * Handles the fulfilled state when the happiness cart data is successfully fetched.
       *
       * @param {CartState} state - The current state of the cart.
       * @param {PayloadAction<CartData>} action - The action containing the fetched cart data.
       */
      .addCase(fetchDeleteHappinessCart.fulfilled, (state, action: PayloadAction<CartData | false>) => {
        state.loading = false;
        if (action.payload) {
          state.data = action.payload as CartData; // Set cart data if available
        } else {
          state.data = null; // No cart data (false case)
        }
        // state.data = action.payload;
      })

      /**
       * Handles the rejected state when fetching the happiness cart fails.
       *
       * @param {CartState} state - The current state of the cart.
       * @param {PayloadAction<any>} action - The action containing the error message.
       */
      .addCase(fetchDeleteHappinessCart.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


  },
});

/**
 * Selector to get the number of items in the order.
 *
 * @function selectOrderItemsLength
 * @param {Object} state - The global Redux state.
 * @returns {number} The number of items in the order, or 0 if none exist.
 */
export const selectOrderItemsLength = (state: { happinessCart: CartState }) => {
  return state.happinessCart.data?.order_items?.length || 0;
};

export const { happiness_cart_loyalty_points } = happinessCartSlice.actions;

export default happinessCartSlice.reducer;
