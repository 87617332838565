// components/ui/HappinessCards.tsx
"use client";
import Icon from "@/components/Icon";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "@/store/store";
// import { fetchPromotionsVouchers } from "@/store/slices/promotionsAndVoucher";
import { useSession } from "next-auth/react";
import HapinnessCardSkeletons from "@/components/skeletons/HapinnessCardSkeletons";
import HapinessCard from "@/components/ui/HapinessCard";
import Image from "next/image";
import { openPopup } from "@/store/slices/auth/signPopupSlice";
import { useCartActions } from "@/hooks/useCartActions";
import Link from "next/link";
import FixCartBar from "@/app/vouchers/components/FixCartBar";
import Cart from "@/components/sidebars/cartSidebar/Cart";
import Button from "@/components/ui/Button";

const HappinessCards = () => {
    const { data: session } = useSession();
    const dispatch = useDispatch<AppDispatch>();
    const { loading, results } = useSelector((state: AppState) => state.promotionsVouchers);
    const prevRef = useRef<HTMLButtonElement>(null);
    const nextRef = useRef<HTMLButtonElement>(null);
    const [hapinessCardList, setHappinessCardList] = useState<any>([]);
    const [visibleCount, setVisibleCount] = useState<number>(4);
    const [isCartSidebarOpen, setIsCartSidebarOpen] = useState(false);
   
    useEffect(() => {
        if (results) {
            setHappinessCardList(results.vouchers);
        }
    }, [results]);

    const handleClick = () => {
        setVisibleCount(prevCount => Math.min(prevCount + 4, hapinessCardList.length));
    };

    const { increment, decrement } = useCartActions();
    const { data: cartData } = useSelector((state: AppState) => state.happinessCart);

    const findInCart = (ProductId: number) => {
        return cartData?.order_items?.find((item) => item.product_id === ProductId);
    };
    return (
        <section className="md:pt-[50px] md:pb-[25px] py-6 relative" id="HappinessCard">
            <div className="container">
                <div className="flex items-center justify-between mb-4 md:mb-9">
                    <h2 className="font-poppins-32-regular !font-medium">Happiness Cards</h2>
                    <div className="flex gap-3 items-center">
                        <Button id="home-hapiness-view-all" variant="outline" href={"/vouchers"} className="py-1.5 text-xs md:text-base">View All</Button>
                        <div className="md:flex hidden items-center gap-[12px]">
                            <button className="w-[52px] h-[52px] rotate-[-180deg]" ref={prevRef}>
                                <Icon name="sliderRight" className="w-full h-full" />
                            </button>
                            <button className="w-[52px] h-[52px]" ref={nextRef}>
                                <Icon name="sliderRight" className="w-full h-full" />
                            </button>
                        </div>
                    </div>
                </div>

                <Swiper
                    slidesPerView={2}
                    spaceBetween={30}
                    modules={[Navigation]}
                    navigation={{
                        prevEl: prevRef && prevRef.current,
                        nextEl: nextRef && nextRef.current,
                    }}
                    className="tmbMySwiper md:!block !hidden"
                >
                    {loading && (
                        <HapinnessCardSkeletons />
                    )}

                    {!loading && hapinessCardList.map((voucher: any) => (
                        <SwiperSlide key={voucher.voucher_id}>
                            <HapinessCard voucher={voucher} />
                        </SwiperSlide>
                    ))}
                </Swiper>

                {/* Mobile View */}
                <div className="md:hidden block">
                    {!loading && (
                        <div className="grid grid-cols-2 gap-[16px]">
                            {hapinessCardList?.slice(0, visibleCount)?.map((item: any) => {
                                const formattedPrice = new Intl.NumberFormat('en-US').format(item.price?.number);
                                const inCart = findInCart(item.voucher_id);
   
                                return (
                                    <div key={item.voucher_name} className="rounded-lg md:p-3 p-2 border border-gray-2">
                                        <Link href={`/vouchers/${(item?.voucher_url).replace(`${process.env.NEXT_PUBLIC_API_REPLACE}/vouchers/`, "").replace(`${process.env.NEXT_PUBLIC_API_REPLACE_1}/vouchers/`, "")}`}>
                                            <Image
                                                loading="lazy"
                                                src={`${item?.image}`}
                                                width={569}
                                                height={182}
                                                alt="error"
                                                className="rounded-t-lg md:rounded-xl block max-w-full xl:max-w-[270px] w-full mb-2"
                                            />
                                        </Link>
                                        <h3 className="md:text-xl text-xs leading-[14.4px] md:leading-7 font-inter text-wireframe-heading mb-2 font-bold">
                                            {item.voucher_name}
                                            <Link 
                                            href={`/vouchers/${(item?.voucher_url).replace(`${process.env.NEXT_PUBLIC_API_REPLACE}/vouchers/`, "").replace(`${process.env.NEXT_PUBLIC_API_REPLACE_1}/vouchers/`, "")}`}
                                             className="opacity-70 font-semibold whitespace-nowrap ml-1 underline">View Detail</Link>
                                        </h3>
                                        <div className="flex items-center gap-2 justify-between flex-wrap">
                                            <p className="text-[14px] leading-4 font-poppins font-semibold text-gray-5 ">
                                                ₹ {formattedPrice}
                                            </p>
                                            {inCart ? (
                                                <div className="flex items-center justify-evenly space-x-2 border border-orange-500 rounded-lg w-full lg:w-20 p-[7px] md:p-2 h-[34px]">
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (!session) {
                                                                dispatch(openPopup(true));
                                                                return;
                                                            }
                                                            decrement(item.voucher_id);
                                                        }}
                                                        className="text-primary-5 font-inter font-normal leading-[22.4px] text-base"
                                                    >
                                                        –
                                                    </button>
                                                    <span className="text-primary-5 font-inter font-normal leading-[22.4px] text-base">
                                                        {inCart.quantity}
                                                    </span>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (!session) {
                                                                dispatch(openPopup(true));
                                                                return;
                                                            }
                                                            increment(item.voucher_id);
                                                        }}
                                                        className="text-primary-5 font-inter font-normal leading-[22.4px] text-base"
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ) : (
                                                <button
                                                    id={`voucher-btn-${item?.voucher_id}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!session) {
                                                            dispatch(openPopup(true));
                                                            return;
                                                        }
                                                        increment(item.voucher_id);
                                                    }}
                                                    className="justify-center font-inter px-3 text-base font-normal rounded-lg border duration-300  bg-primary-5 text-white border-primary-5 hover:bg-transparent hover:text-primary-5 hover:shadow-shadow-btn flex items-center group max-[767px]:py-[7px] lg:w-auto w-full min-w-20 h-[34px]"
                                                >
                                                    <span>ADD</span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {!loading && visibleCount < hapinessCardList.length && (
                        <div className="flex justify-center items-center w-full pt-4">
                            <button
                                onClick={handleClick}
                                className="px-[36px] py-[12px] justify-center font-inter text-base font-normal rounded-lg border duration-300 leading-[22.4px] bg-transparent text-primary-5 border-primary-5 hover:bg-primary-5 hover:text-white hover:shadow-shadow-btn flex items-center group max-[767px]:py-[7px]"
                            >
                                <span>View more</span>
                            </button>
                        </div>
                    )}
                </div>
                <FixCartBar onButtonClick={() => setIsCartSidebarOpen(true)} className="bottom-[78px] md:static md:mt-4 md:rounded-lg" />
            </div>
            <Cart isCartSidebarOpen={isCartSidebarOpen} closeCartSidebar={() => setIsCartSidebarOpen(false)} />
        </section>
    );
};

export default HappinessCards;
