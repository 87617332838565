"use client";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNearbyBranches } from "@/store/slices/table_booking_flow/branchesSlice";
import { AppDispatch, AppState } from "@/store/store";
import Image from "next/image";
import Link from "next/link";

const RestaurantsNearYou = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { data: nearbyBranches, loadingNearbyBranches, errorNearbyBranches } = useSelector(
        (state: AppState) => state.branches
    );
    const [userLocation, setUserLocation] = useState<{ latitude: number; longitude: number } | null>(null);

    // Function to fetch location
    const fetchLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const newLocation = { latitude, longitude };
                    setUserLocation(newLocation);
                    localStorage.setItem("userLocation", JSON.stringify(newLocation));

                    // Dispatch action to fetch nearby branches
                    dispatch(
                        fetchNearbyBranches({
                            lat: latitude.toString(),
                            long: longitude.toString(),
                        })
                    );
                },
                (error) => {
                    console.error("Error fetching location:", error.message);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 0,
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };

    useEffect(() => {
        const savedLocation = localStorage.getItem("userLocation");
        if (savedLocation) {
            // Use saved location if available
            const parsedLocation = JSON.parse(savedLocation);
            setUserLocation(parsedLocation);
            dispatch(
                fetchNearbyBranches({
                    lat: parsedLocation.latitude.toString(),
                    long: parsedLocation.longitude.toString(),
                })
            );
        } else {
            // Fetch location if not already saved
            fetchLocation();
        }
    }, [dispatch]);

    // Match nearby branches with branches in cities data
    const matchedBranches = nearbyBranches?.near_by
        ?.map((nearbyBranch: any) => {
            const matchedCity = nearbyBranches?.data.find((city: any) =>
                city?.branches.some((branch: any) => branch?.branch_id === nearbyBranch?.branch_id)
            );

            if (matchedCity) {
                return matchedCity?.branches.find((branch: any) => branch?.branch_id === nearbyBranch?.branch_id);
            }
            return null;
        })
        .filter(Boolean);

    return (
        <>
            {matchedBranches?.length > 0 && (
                <section className="md:hidden py-6 relative">
                    <div className="container">
                        <h2 className="font-poppins text-xl font-medium leading-6 text-wireframe-heading">
                            Restaurants Near You
                        </h2>
                        <div className="mt-4">
                            {loadingNearbyBranches ? (
                                <p>Loading nearby restaurants...</p>
                            ) : errorNearbyBranches ? (
                                <p className="text-red-500">Error loading nearby restaurants: {errorNearbyBranches}</p>
                            ) : matchedBranches && matchedBranches?.length > 0 ? (
                                <div className="grid grid-cols-2 gap-4">
                                    {matchedBranches?.map((branch: any, index: number) => (
                                        <Link
                                            href={branch.branch_alias || "#"}
                                            key={index}
                                            className="p-2 border border-gray-200 rounded-lg shadow-sm bg-white flex flex-col gap-2"
                                        >
                                            <Image
                                                src={branch?.branch_image}
                                                alt={branch.branch_name}
                                                width={150}
                                                height={80}
                                                className="rounded-md object-cover h-20 w-full"
                                            />
                                            <div>
                                                <h3 className="font-semibold font-inter text-gray-800 line-clamp-2">
                                                    {branch?.branch_name}
                                                </h3>
                                                <p className="text-sm font-inter text-gray-600 line-clamp-2">{branch?.branch_address}</p>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            ) : (
                                <p className="font-inter text-center">No nearby restaurants found.</p>
                            )}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default RestaurantsNearYou;
