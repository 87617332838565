import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import Button from '@/components/ui/Button';
import { cn, formatCurrency } from '@/lib/utils';
import HappinessCard from './HappinessCard';
// import ApplyOffers from './ApplyOffers';
import PricingDetails from './PricingDetails';
// import GiftCardRecipient from './GiftCardRecipient';
// import ApplyCouponsForGiftCard from './ApplyCouponsForGiftCard';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '@/store/store';
import useFetch from '@/hooks/useFetch';
import Script from 'next/script';
import { useSession } from 'next-auth/react';
import { openPopup } from '@/store/slices/auth/signPopupSlice';
import { utmSourceSaved } from '@/store/slices/utm/utmSlice';
import { toast } from 'react-toastify';
import { fetchHappinessCart } from '@/store/slices/happiness-card/happinessCartSlice';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import PaymentOptions from '../snippets/PaymentOptions';

// Props interface for the Cart component
interface CartProps {
  isCartSidebarOpen: boolean;
  closeCartSidebar: () => void;
}

const Cart: React.FC<CartProps> = ({ isCartSidebarOpen, closeCartSidebar }) => {
  

  const router = useRouter()
  const { data: session } = useSession()
  const dispatch = useDispatch<AppDispatch>();
  // Custom hook for fetching data
  const { fetchData, data , loading, error } = useFetch<any>()

  // Accessing cart data from the Redux store
  const { data: cartData, loading: cartLoading, error: cartError } = useSelector((state: AppState) => state.happinessCart);
  const { user: userData, loading: userLoading, error: userError } = useSelector((state: AppState) => state.user);


  const loyalty_points = useSelector((state: AppState) => state.happinessCart.loyalty_points) || 0;

  const [activeIndex, setActiveIndex] = useState(0);
  const tabs = [
    { label: "Personal" },
    { label: "Send as Gift" },
  ];

  // Handle tab click to change the active tab index
  const handleTabClick = (index: number) => {
    setActiveIndex(index);
  };

  // Function to proceed to checkout by fetching data
  const proceedToCheckOut = () => {
    if (!session) {
      dispatch(openPopup(true));
      return;
    } else {
      let endPoints = '/cart/generate-rp-order';
      if (loyalty_points) {
        endPoints = `/cart/generate-rp-order?discount=${loyalty_points}&loyalty_points=${loyalty_points}`;
      }
      fetchData(endPoints, {
        method: "GET"
      })
    }
  }

  // Effect hook to trigger Razorpay preferences API call upon successful data fetch
  useEffect(() => {
    if (data) {
      try {
        //order_id
        if (typeof window !== 'undefined' && window.Razorpay) {
          const paymentObject = new window.Razorpay({
            ...data,
            prefill: {
              ...(userData?.name && { "name": userData?.name }),
              ...(userData?.email && { "email": userData?.email }),
              ...(session?.user?.mobile_number && { "contact": session?.user?.mobile_number }),
            },
            handler: function (response: any) {
              // This function is called on payment success
              console.log('Payment Successful:', response);
              // alert('Payment Successful!');

              console.log('Payment ID:', response.razorpay_payment_id);
              console.log('Order ID:', response.razorpay_order_id);
              console.log('Signature:', response.razorpay_signature);

              // Example of sending the data to your server:
              fetch(`${process.env.NEXT_PUBLIC_SITE_URL}/api/v1/cart/checkout`, { /// post data after payment 
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  points: loyalty_points || 0,
                  barCode: "",
                  razorpay_payment_id: response?.razorpay_payment_id,
                  razorpay_order_id: response?.razorpay_order_id,
                  razorpay_signature: response?.razorpay_signature
                }),
              }).then(res => res.json())
                .then(data => {
                  toast.success(`Payment successfully completed`)
                  router.push('/vouchers/voucher-confirmation')
                  closeCartSidebar()
                })
                .catch(error => {
                  toast.error(`Error verifying payment: ${error}`)
                });

              dispatch(utmSourceSaved({ "booking_id": 'bbq_' + cartData?.order_id, "order_type": "Happiness Card" })) // utm
              dispatch(fetchHappinessCart({ session: session ? true : false })) // reload cart
              
            }
          });

          // Handle payment failure
          paymentObject.on('payment.failed', function (response: any) {
            // Show the failure reason to the user
            console.log('Payment Failed: ' + response.error.description);

            // Optionally log the error for further debugging or analytics
            console.log('Error code:', response.error.code);
            console.log('Description:', response.error.description);
            console.log('Source:', response.error.source);
            console.log('Step:', response.error.step);
            console.log('Reason:', response.error.reason);
            console.log('Order ID:', response.error.metadata.order_id);
            console.log('Payment ID:', response.error.metadata.payment_id);
          });

          // Open the payment interface
          paymentObject.open();
        }
      } catch (error) {
        console.log("error : ", error);
      }
    }

  }, [data])

  return (
    <Sidebar
      isOpen={isCartSidebarOpen}
      onClose={closeCartSidebar}
      heading={<h2 className='font-inter-20-semibold'>Cart</h2>}
      footer={
        <>
          {/* Display footer only if there are items in the cart */}

          {isCartSidebarOpen && (
            !cartData || !('order_items' in cartData) ? (
              ''
            ) : (
              Array.isArray(cartData.order_items) && cartData.order_items?.length === 0 ? (
                ''
              ) : (
                // Render the items in the cart or any other UI you want
                <div className='flex justify-between gap-3'>
                  <div className='flex gap-1.5  md:gap-2.5  items-center'>
                    <span className='font-inter text-[#21272A] text-lg md:text-2xl font-semibold'>
                      {cartData && cartData?.total_price && formatCurrency(cartData?.total_price - loyalty_points, 'en-IN', 'INR')}
                    </span>
                    <span className='font-inter text-[#21272A] text-sm font-semibold'>To Pay</span>
                  </div>
                  <Button id={`cart-sidebar-checkout-btn`} className='text-sm font-bold leading-[16.8px]' onClick={
                    () => proceedToCheckOut()
                  }>Proceed To Checkout</Button>

                  {/* /cart/generate-rp-order */}
                </div >
              )
            )
          )}
        </>
      }
    >
      {/* Display message if cart is empty, otherwise render cart content */}

      {isCartSidebarOpen && (
        !cartData || !('order_items' in cartData) ? (
          <div className='flex items-center justify-center h-full'>
            <div>
              <p className='font-inter text-gray-5 mb-4'>Your cart is currently empty.</p>
              <Image loading="lazy" src="/images/empty-cart.png" width={96} height={80} alt='no voucher' className='text-center mx-auto mb-5' />
              <Button href='/vouchers' onClick={() => closeCartSidebar()}>
                Buy Happiness Cards
              </Button>
            </div>
          </div>
        ) : (
          Array.isArray(cartData.order_items) && cartData?.order_items?.length === 0 ? (
            <div className='flex items-center justify-center h-full'>
              <div>
                <p className='font-inter text-gray-5 mb-4'>Your cart is currently empty.</p>
                <Image loading="lazy" src="/images/empty-cart.png" width={96} height={80} alt='no voucher' className='text-center mx-auto mb-5' />
                <Button href='/vouchers' onClick={() => closeCartSidebar()}>
                  Buy Happiness Cards
                </Button>
              </div>
            </div>
          ) : (
            // Render the items in the cart or any other UI you want
            <>
              <Script src='https://checkout.razorpay.com/v1/checkout.js' defer crossOrigin='anonymous' />
              <div>
                {/* <div className="flex space-x-2 mb-4 p-2.5 bg-gray-1 rounded-lg"> */}
                {/* Tab navigation for Personal and Send as Gift options */}
                {/* {tabs?.map((tab, index) => (
                    <button
                      key={index}
                      className={cn(
                        `duration-300 text-center leading-[22.4px] max-[767px]:whitespace-nowrap font-inter px-[10px] py-[6px] md:py-2.5 text-xs md:text-base font-normal rounded-lg w-full ${activeIndex === index
                          ? "bg-primary-5 shadow-[12px_0_24px_#0000000A] text-white"
                          : "text-gray-5"
                        }`
                      )}
                      onClick={() => handleTabClick(index)}
                    >
                      {tab.label}
                    </button>
                  ))} 
                </div>*/}

                {/* Content for the selected tab */}
                <div className="">
                  {activeIndex === 0 &&
                    <>
                      <HappinessCard />
                      {/* <ApplyOffers /> */}
                      <PricingDetails />
                      <PaymentOptions />
                    </>
                  }

                  {/* 
                  {activeIndex === 1 &&
                    <>
                      <HappinessCard />
                      <GiftCardRecipient />
                      <ApplyCouponsForGiftCard />
                      <PricingDetails />
                      <PaymentOptions />
                    </>
                  } */}
                </div>
              </div>
            </>
          )
        )
      )}

    </Sidebar >
  );
};

export default Cart;
