import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/**
 * Type representing a Promotion object.
 * @typedef {Object} Promotion
 * @property {number} promotion_id - The unique ID of the promotion.
 * @property {string} promotion_title - The title of the promotion.
 * @property {string} promotion_description - The description of the promotion.
 * @property {string} promotion_image - The URL of the promotion image.
 * @property {string} promotion_url - The URL where the promotion redirects.
 */
export type Promotion = {
  promotion_id: number;
  promotion_title: string;
  promotion_description: string;
  promotion_image: string;
  promotion_url: string;
  promotion_alias: string;
};

/**
 * Type representing the Price object within a Voucher.
 * @typedef {Object} Price
 * @property {number} number - The price amount.
 * @property {string} currency_code - The currency code (e.g., "USD", "INR").
 */
type Price = {
  number: number;
  currency_code: string;
};

/**
 * Type representing a Voucher object.
 * @typedef {Object} Voucher
 * @property {number} voucher_id - The unique ID of the voucher.
 * @property {string} voucher_url - The URL where the voucher can be redeemed.
 * @property {string} voucher_name - The name of the voucher.
 * @property {string} image - The URL of the voucher image.
 * @property {string} field_card - Additional field card information.
 * @property {Price} price - The price object containing price details.
 */
export type Voucher = {
  voucher_id: number;
  voucher_url: string;
  voucher_name: string;
  image: string;
  field_card: string;
  price: Price;
};

/**
 * Type representing the combined Promotions and Vouchers object.
 * @typedef {Object} PromotionsAndVouchers
 * @property {Promotion[]} promotion - Array of promotions.
 * @property {Voucher[]} vouchers - Array of vouchers.
 */
export type PromotionsAndVouchers = {
  promotion: Promotion[];
  vouchers: Voucher[];
};

/**
 * Type representing the API response structure.
 * @typedef {Object} ApiResponse
 * @property {PromotionsAndVouchers} results - The main result object containing promotions and vouchers.
 */
export type ApiResponse = {
  results: PromotionsAndVouchers;
};

/**
 * Type representing the state structure for the Promotions and Vouchers slice.
 * @typedef {Object} PromotionsVouchersState
 * @property {PromotionsAndVouchers} results - The results object containing promotions and vouchers.
 * @property {string | null} error - Error message if any error occurs.
 * @property {boolean} loading - Loading state to indicate if data is being fetched.
 */
type PromotionsVouchersState = {
  results: PromotionsAndVouchers;
  error: string | null;
  loading: boolean;
};

/**
 * Initial state for the Promotions and Vouchers slice.
 * @type {PromotionsVouchersState}
 */
const initialState: PromotionsVouchersState = {
  results: {
    promotion: [],
    vouchers: []
  },
  error: null,
  loading: false
};

/**
 * Async thunk for fetching promotions and vouchers data from the API.
 * 
 * @async
 * @function fetchPromotionsVouchers
 * @param {Object} payload - The payload containing latitude, longitude, and branch ID.
 * @param {string} payload.lat - Latitude of the location.
 * @param {string} payload.long - Longitude of the location.
 * @param {number} payload.branch_id - Branch ID for which promotions and vouchers are being fetched.
 * @returns {Promise<PromotionsAndVouchers>} The fetched promotions and vouchers data.
 * @throws Will throw an error if the API call fails.
 */
export const fetchPromotionsVouchers = createAsyncThunk(
  'promotionsVouchers/promotions',
  async (
    { lat, long, branch_id }: { lat: string; long: string; branch_id: number },
    { rejectWithValue }
  ) => {

    // Detect Lighthouse user-agent
    const isLighthouse = /Lighthouse/i.test(window.navigator.userAgent);

    if(isLighthouse){ // bypass lighthouse bot
      return rejectWithValue('Skipping API call for Lighthouse');
    }

    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        lat: '0',
        long: '0',
        branch_id
      });

      const response = await fetch("/api/v1/promotions", {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow" as RequestRedirect,
        next: { revalidate: 0 }
      },
      );

      if (!response.ok) {
        const errorData = await response.json();
        if(errorData?.error.includes('Blocked by Cloudflare'))
          toast.warn(`${errorData?.error}`)
        return rejectWithValue(errorData);
      }

      const result = await response.json();
      return result?.results;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Slice for managing promotions and vouchers state.
 * It includes the reducer and extra reducers for handling async thunk actions.
 */
const promotionsVouchersSlice = createSlice({
  name: 'promotionsVouchers',
  initialState,
  reducers: {
    // Define other reducers if needed
  },
  extraReducers: (builder) => {
    builder
      /**
       * Handles the pending state when fetching promotions and vouchers.
       * 
       * @param {PromotionsVouchersState} state - The current state of the slice.
       */
      .addCase(fetchPromotionsVouchers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      /**
       * Handles the fulfilled state when promotions and vouchers are successfully fetched.
       * 
       * @param {PromotionsVouchersState} state - The current state of the slice.
       * @param {PayloadAction<PromotionsAndVouchers>} action - The action containing the fetched promotions and vouchers data.
       */
      .addCase(fetchPromotionsVouchers.fulfilled, (state, action: PayloadAction<PromotionsAndVouchers>) => {
        state.loading = false;
        state.results = action.payload;
      })

      /**
       * Handles the rejected state when fetching promotions and vouchers fails.
       * 
       * @param {PromotionsVouchersState} state - The current state of the slice.
       * @param {PayloadAction<any>} action - The action containing the error message.
       */
      .addCase(fetchPromotionsVouchers.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { } = promotionsVouchersSlice.actions;
export default promotionsVouchersSlice.reducer;
